<template>
    <div class="intro-y box p-5 mt-5">
        <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
            <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                <Preloader />
            </div>

            <div class="relative col-span-6">
                <label for="form-preview-title" class="form-label">
                    Название в превью
                    <sup v-if="v$.form.fields.preview_title.required" class="text-theme-6">*</sup>
                </label>
                <label
                    v-if="v$.form.fields.preview_title.$errors.length"
                    for="form-preview-title"
                    class="form-input-text-error"
                >
                    {{ getOverrideMessage(v$.form.fields.preview_title.$errors[0].$message) }}
                </label>
                <input
                    id="form-preview-title"
                    v-model="form.fields.preview_title"
                    type="text"
                    class="form-control w-full"
                    :class="{ 'form-input-error': v$.form.fields.preview_title.$errors.length }"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="relative col-span-6">
                <label for="form-title" class="form-label">
                    Название на детальной странице
                    <sup v-if="v$.form.fields.title.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.fields.title.$errors.length" for="form-title" class="form-input-text-error">
                    {{ getOverrideMessage(v$.form.fields.title.$errors[0].$message) }}
                </label>
                <input
                    id="form-title"
                    v-model="form.fields.title"
                    type="text"
                    class="form-control w-full"
                    :class="{ 'form-input-error': v$.form.fields.title.$errors.length }"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="relative col-span-6">
                <label for="form-link" class="form-label">
                    Ссылка
                    <sup v-if="v$.form.fields.slug.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.fields.slug.$errors.length" for="form-link" class="form-input-text-error">
                    {{ getOverrideMessage(v$.form.fields.slug.$errors[0].$message) }}
                </label>
                <input
                    id="form-link"
                    v-model="form.fields.slug"
                    type="text"
                    class="form-control w-full"
                    :class="{ 'form-input-error': v$.form.fields.slug.$errors.length }"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="relative col-span-6" title="Порядковый номер города от 0 до 65535">
                <label for="form-order" class="form-label">
                    Сортировка
                    <sup v-if="v$.form.fields.order.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.fields.order.$errors.length" for="form-order" class="form-input-text-error">
                    {{ getOverrideMessage(v$.form.fields.order.$errors[0].$message) }}
                </label>
                <input
                    id="form-order"
                    v-model="form.fields.order"
                    type="text"
                    class="form-control w-full"
                    :class="{ 'form-input-error': v$.form.fields.order.$errors.length }"
                    placeholder="Порядковый номер города от 0 до 65535"
                    autocomplete="off"
                />
            </div>

            <div v-if="!form.fields.parent_id" class="relative col-span-12">
                <label for="form-navigation_title" class="form-label"> Заголовок для дочерних элементов </label>
                <input
                    id="form-navigation_title"
                    v-model="form.fields.navigation_title"
                    type="text"
                    class="form-control w-full"
                    placeholder="Заголовок для дочерних элементов"
                    autocomplete="off"
                />
            </div>

            <div class="col-span-2 flex items-center pt-6 mb-5">
                <input
                    id="form-published"
                    v-model="form.fields.published"
                    class="show-code form-check-switch flex-none mr-0 ml-3"
                    type="checkbox"
                />
                <label for="form-published" class="form-check-label">Опубликовано</label>
            </div>

            <div class="col-span-6">
                <label for="form-parent_id" class="form-label">Родительское направление</label>
                <Multiselect
                    id="form-parent_id"
                    v-model="form.fields.parent_id"
                    track-by="title"
                    label="title"
                    value-prop="id"
                    placeholder="Выберите родителя"
                    :hide-selected="false"
                    :close-on-select="false"
                    :searchable="true"
                    :options="parentOptions"
                />
            </div>

            <div class="col-span-4">
                <label for="form-sale_visible" class="form-label">Текст акции на картинке</label>
                <select id="form-sale_visible" v-model="form.sale_visible" class="form-select">
                    <option :value="true">Виден</option>
                    <option :value="false">Скрыт</option>
                </select>
            </div>

            <div class="col-span-12">
                <label for="form-description" class="form-label">Описание</label>
                <CustomWyswig id="form-description" v-model="form.fields.description" class="form-control w-full" />
            </div>

            <div class="relative col-span-12">
                <label class="form-label">
                    Изображение
                    <sup v-if="v$.form.fields.image.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.fields.image.$errors.length" class="form-image-text-error">
                    {{ getOverrideMessage(v$.form.fields.image.$errors[0].$message) }}
                </label>
                <UploadImageNew
                    v-model="form.fields.image"
                    recommended-sizes="1170x300px"
                    :has-error="!!v$.form.fields.image.$errors.length"
                ></UploadImageNew>
            </div>

            <div class="col-span-12">
                <label class="form-label">Превью изображение</label>
                <UploadImageNew v-model="form.fields.image_preview" recommended-sizes="350x210px"></UploadImageNew>
            </div>

            <div class="col-span-12">
                <label for="form-attractions-title" class="form-label">Заголовок достопримечательностей</label>
                <input
                    id="form-attractions-title"
                    v-model="form.fields.attractions_title"
                    type="text"
                    class="form-control w-full"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="col-span-12">
                <label class="form-label font-bold">Достопримечательности</label>
                <p v-if="attractionOptions.length === 0">Отсутствуют</p>
                <MultiselectTable
                    v-else
                    v-model="form.attractions"
                    :options="attractionOptions"
                    :header-labels="headerLabels"
                />
            </div>

            <div class="col-span-12">
                <label for="form-search-title" class="form-label">Заголовок поиска</label>
                <input
                    id="form-search-title"
                    v-model="form.fields.search_title"
                    type="text"
                    class="form-control w-full"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="col-span-12">
                <label class="form-label font-bold">Кастомные поля</label>
                <TeamsTable v-if="form.seo" v-model="form.seo" :meta="null" :headers="seoHeaders" class="mb-3" />
                <SearchParamFillInfo />
            </div>

            <div class="col-span-12 flex items-center justify-end mt-5">
                <router-link :to="{ name: 'city-directions' }" class="btn btn-secondary w-24 mr-2 mb-2">
                    Отмена
                </router-link>
                <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(false)">
                    Сохранить и выйти
                </button>
                <button type="submit" class="btn btn-primary mb-2" @click.prevent="save(true)">Сохранить</button>
            </div>
        </form>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, numeric, minValue, maxValue } from '@vuelidate/validators';
import Preloader from '@/components/preloader/Main';
import UploadImageNew from '@/components/upload-image/UploadImageNew';
import CustomWyswig from '@/components/base/custom-wyswig/Main';
import TeamsTable from '@/components/tables/TeamsTable';
import { cityDirectionsMixin } from '@/mixins/form/city-directions-mixin';
import { errorResponse, formPreparation } from '@/mixins/form/form-mixin';
import Multiselect from '@vueform/multiselect';
import SearchParamFillInfo from '@/components/banners/SearchParamFillInfo';
import MultiselectTable from '@/components/tables/MultiselectTable';
import { helper as $h } from '@/utils/helper';

export default {
    name: 'Create',
    components: {
        MultiselectTable,
        SearchParamFillInfo,
        Preloader,
        UploadImageNew,
        CustomWyswig,
        TeamsTable,
        Multiselect,
    },
    mixins: [cityDirectionsMixin, errorResponse, formPreparation],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            headerLabels: ['Достопримечательность'],
        };
    },
    validations() {
        return {
            form: {
                fields: {
                    preview_title: { required },
                    title: { required },
                    slug: { required },
                    order: { required, numeric, minValue: minValue(0), maxValue: maxValue(65535) },
                    image: { required },
                },
            },
        };
    },
    created() {
        this.$store.commit('main/setPageHeader', 'Создать город отправления');
        this.fetchCreationParams();
    },
    methods: {
        async save(flag) {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;

            try {
                const formData = this.formPreparation({ nullsAsUndefineds: false, imagesAsObject: true });
                const { data } = await this.axios.post('/city-directions', formData);
                this.$notify(data.message);
                if (flag) {
                    await this.$router.push({ name: 'city-direction-edit', params: { id: data.data.id } });
                } else {
                    await this.$router.push({ name: 'city-directions' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            } finally {
                this.loading = false;
            }
        },
        async fetchCreationParams() {
            this.loading = true;

            try {
                const { data } = await this.axios.get(`/city-directions/create`);

                this.parentOptions = data.data.directions;
                this.attractionOptions = data.data.attractions;
            } catch (ex) {
                console.log('cannot fetch city-directions creation params ' + ex);
                // this.$router.push('/error-page');
            } finally {
                this.loading = false;
            }
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
    },
};
</script>

<style scoped></style>
